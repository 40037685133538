export const rules = {
  name: [
    {
      required: true,
      message: '账号是必填内容',
      trigger: 'change'
    },
    {
      pattern: /^[a-z0-9]{3,10}$/,
      message: '用户名必须是5-10个字母或数字',
      trigger: 'change'
    }
  ],
  password: [
    {
      required: true,
      message: '密码是必填内容',
      trigger: 'change'
    },
    {
      pattern: /^[a-z0-9]{3,}$/,
      message: '用户名必须是3位以上的字母或数字',
      trigger: 'change'
    }
  ]
}
