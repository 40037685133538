import _objectSpread from "F:/Users/Administrator/Desktop/myFiles/repository/private/code/project/miniprogram-order-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import { defineComponent, reactive, ref } from 'vue';
import { rules } from '../config/account-config';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import localCache from '@/utils/cache';
export default defineComponent({
  name: 'LoginAccount',
  setup: function setup() {
    var _localCache$getCache, _localCache$getCache2;

    var store = useStore();
    var account = reactive({
      name: (_localCache$getCache = localCache.getCache('name')) !== null && _localCache$getCache !== void 0 ? _localCache$getCache : '',
      password: (_localCache$getCache2 = localCache.getCache('password')) !== null && _localCache$getCache2 !== void 0 ? _localCache$getCache2 : ''
    });
    var formRef = ref();

    var loginAction = function loginAction(isKeepPassword) {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(function (validate) {
        // 验证输入格式是否正确
        if (validate) {
          // 是否记住密码
          if (isKeepPassword) {
            localCache.setCache('name', account.name);
            localCache.setCache('password', account.password);
          } else {
            localCache.deleteCache('name');
            localCache.deleteCache('password');
          }

          store.dispatch('login/accountLoginAction', _objectSpread({}, account));
        } else {
          ElMessage.error('输入格式错误,请根据提示输入');
        }
      });
    };

    return {
      account: account,
      rules: rules,
      loginAction: loginAction,
      formRef: formRef
    };
  }
});