import { defineComponent, ref } from 'vue';
import LoginAccount from './login-account.vue';
import { User } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'loginPanel',
  components: {
    LoginAccount: LoginAccount,
    User: User
  },
  setup: function setup() {
    // 1.定义属性
    var isKeepPassword = ref(true);
    var accountRef = ref();
    var phoneRef = ref();
    var currentTab = ref('account');

    var handleLoginClick = function handleLoginClick() {
      if (currentTab.value === 'account') {
        var _accountRef$value;

        (_accountRef$value = accountRef.value) === null || _accountRef$value === void 0 ? void 0 : _accountRef$value.loginAction(isKeepPassword.value);
      } else {
        var _phoneRef$value;

        (_phoneRef$value = phoneRef.value) === null || _phoneRef$value === void 0 ? void 0 : _phoneRef$value.phoneAction();
      }
    };

    return {
      isKeepPassword: isKeepPassword,
      accountRef: accountRef,
      phoneRef: phoneRef,
      currentTab: currentTab,
      handleLoginClick: handleLoginClick
    };
  }
});