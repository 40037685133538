import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "login-account"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    "label-width": "60px",
    rules: _ctx.rules,
    model: _ctx.account,
    class: "login-phone-tabs",
    ref: "formRef"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "账号",
        prop: "name"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: _ctx.account.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.account.name = $event;
            }),
            size: "large"
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "密码",
        prop: "password"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: _ctx.account.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return _ctx.account.password = $event;
            }),
            type: "password",
            size: "large",
            "show-password": "",
            class: "password-box"
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["rules", "model"])]);
}